import './main.pcss';
import App from './App.svelte';


document.addEventListener('DOMContentLoaded', () => {
  new App({
    target: document.body
  });
});


